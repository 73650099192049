<template>
  <component :is="tourToUse" />
</template>

<script setup>
  import TourPlus from "@/components/Tour/Plus/index.vue"
  import TourClassic from "@/components/Tour/Classic/index.vue"

  const inAgeRestrictedLocation = inject("inAgeRestrictedLocation")
  if (inAgeRestrictedLocation) {
    navigateTo("/tours/sfw/index.php")
  }

  const currentSite = inject("currentSite")
  const tourToUse = computed(() => {
    if (!currentSite.value) return null

    switch (currentSite.value.slug) {
      case "pornplus":
        return markRaw(TourPlus)
      default:
        return markRaw(TourClassic)
    }
  })

  const route = useRoute()
  const pageNumber = parseInt(route.query.page) || 1
  useSiteSeo({
    defaultTitle: "Check out all of our videos on {{ siteName }}!",
    defaultDescription: "Watch all {{ siteName }} videos.",
    pageNumber,
    showPageNumber: () => pageNumber > 1,
  })
</script>
